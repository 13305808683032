import { Route, Routes } from 'react-router';
import HomePage from './pages/Home';
import ParentsPage from './pages/Parents';
import PioneersPage from './pages/Pioneers';
import TherapistsPage from './pages/Therapists';

export default function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="parents" element={<ParentsPage />} />
      <Route path="pioneers" element={<PioneersPage />} />
      <Route
        path="3e579f1e-dd81-410a-8dc7-39337e71bb06"
        element={<TherapistsPage />}
      />
    </Routes>
  );
}
