import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const impulseAnimation = require('../../../assets/animations/impulse.json');

interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export default function ImpulseSection({ sectionRef }: Props) {
  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    triggerOnce: false,
    delay: 1200,
    threshold: 0.3,
  });
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (inView) {
      lottieRef.current?.play();
    } else {
      lottieRef.current?.goToAndStop(0);
    }
  }, [inView]);

  const handleImpulseButtonClick = () =>
    aboutSectionRef.current?.scrollIntoView({ behavior: 'smooth' });

  return (
    <div ref={sectionRef} className="section pt-6 bg-white">
      <div ref={ref} className="container">
        <div className="block-hero-22 pt-4">
          <div className="row justify-content-center mb-6">
            <div className="col-md-5 text-center">
              <Lottie
                lottieRef={lottieRef}
                animationData={impulseAnimation}
                loop={false}
                autoplay={false}
                width={'100%'}
                onClick={handleImpulseButtonClick}
              />
            </div>
            <div
              className="col-md-7"
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h2 className="h1 fw-semibold display mb-0">
                Help your clients break the cycle
              </h2>
              <h4
                style={{ fontSize: '5rem', lineHeight: '2.5rem' }}
                className="qwitcher-grypen mb-5"
              >
                any time, anywhere
              </h4>
              <p className="lead text-dark op-07 fw-normal">
                The Impulse lock-screen widget is a new first line of defense
                against impulse moments (cravings, urges, or unwanted thoughts).
                A tap of the button provides instant access to coping
                strategies, videos, or other tools — helping you, as their
                therapist, reinforce meaningful progress between sessions.
              </p>
            </div>
          </div>
          <div
            ref={aboutSectionRef}
            style={{ scrollMarginTop: '200px' }}
            id="about"
            className="row justify-content-between col-mb-50 gutter-50"
          >
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/content_marketing.svg"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Personalized Interventions</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Tailor interventions to each client's unique needs, reinforcing
                the therapeutic process and promoting self-regulation.
              </p>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/analysis.svg"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Track Client Progress</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Monitor how clients engage with various coping tools including
                CBT techniques, mindfulness exercises, and personalized
                strategies between sessions.
              </p>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/experience.svg"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Enhanced Session Insights</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Access reports of client interactions and progress, enabling
                more focused and effective therapy sessions based on real-world
                experiences.
              </p>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/maam.png"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Extend Therapeutic Reach</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Bridge the gap between sessions by providing a tool that clients
                can rely on any time of day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
