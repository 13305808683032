import {
  addDoc,
  collection,
  CollectionReference,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';
import { Field, Form, Formik } from 'formik';
import { therapistSchema, TherapistValue } from 'impulse-core';
import { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import Modal from 'react-modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '90%',
    maxHeight: '80%',
    padding: '2rem',
    borderRadius: '8px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
  },
};

type Step = 'form' | 'calendly';

const initialValues: Partial<TherapistValue> = {
  name: '',
  email: '',
  practiceType: '',
};

export default function ContactFormModal({ isOpen, onClose }: Props) {
  const [step, setStep] = useState<Step>('form');
  const [error, setError] = useState('');
  const [submittedValues, setSubmittedValues] = useState<
    Partial<TherapistValue>
  >({});

  const handleModalClose = () => {
    setStep('form');
    onClose();
  };

  const handleSubmit = async (values: typeof initialValues) => {
    setError('');
    setSubmittedValues(values); // store form data

    try {
      const therapistCollection = collection(
        getFirestore(),
        'therapists'
      ) as CollectionReference<TherapistValue>;

      const therapist = {
        ...values,
        name: values.name!,
        email: values.email!,
        practiceType: values.practiceType!,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      await addDoc(therapistCollection, therapist);
      setStep('calendly');
    } catch (err) {
      setError('Something went wrong. Please try again.');
      console.error('Error submitting form:', err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      style={customStyles}
      contentLabel="Contact Form"
    >
      {step === 'form' ? (
        <div className="modal-content border-0">
          <div className="d-flex justify-content-between mb-4">
            <div>
              <h5 className="modal-title m-0">Introduce yourself</h5>
              <p className="text-muted small mb-0 mt-1">
                Fill out the brief form below, and we’ll schedule a time to
                discuss your practice and explore how Impulse’s innovative tools
                can help you support your clients and grow your impact.
              </p>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={handleModalClose}
              aria-label="Close"
            ></button>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={therapistSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, touched, errors }) => (
              <Form className="m-0">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field
                      type="text"
                      className={`form-control ${
                        touched.name && errors.name ? 'is-invalid' : ''
                      }`}
                      id="name"
                      name="name"
                    />
                    {touched.name && errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Field
                      type="email"
                      className={`form-control ${
                        touched.email && errors.email ? 'is-invalid' : ''
                      }`}
                      id="email"
                      name="email"
                    />
                    {touched.email && errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-12">
                    <label htmlFor="practiceType" className="form-label">
                      Type of Practice
                    </label>
                    <Field
                      as="select"
                      className={`form-select ${
                        touched.practiceType && errors.practiceType
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="practiceType"
                      name="practiceType"
                    >
                      <option value="">Select...</option>
                      <option value="solo">Solo Practice</option>
                      <option value="group">Group Practice</option>
                      <option value="clinic">Clinic/Hospital</option>
                      <option value="other">Other</option>
                    </Field>
                    {touched.practiceType && errors.practiceType && (
                      <div className="invalid-feedback">
                        {errors.practiceType}
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <label htmlFor="clientCount" className="form-label">
                      Approximate Number of Active Clients
                    </label>
                    <Field
                      as="select"
                      className={`form-select ${
                        touched.clientCount && errors.clientCount
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="clientCount"
                      name="clientCount"
                    >
                      <option value="">Select...</option>
                      <option value="1-10">1-10</option>
                      <option value="11-25">11-25</option>
                      <option value="26-50">26-50</option>
                      <option value="50+">50+</option>
                    </Field>
                    {touched.clientCount && errors.clientCount && (
                      <div className="invalid-feedback">
                        {errors.clientCount}
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <label htmlFor="specialties" className="form-label">
                      Clinical Focus/Specialties
                    </label>
                    <Field
                      type="text"
                      className={`form-control ${
                        touched.specialties && errors.specialties
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="specialties"
                      name="specialties"
                      placeholder="e.g., Anxiety, Depression, Addiction, OCD"
                    />
                    {touched.specialties && errors.specialties && (
                      <div className="invalid-feedback">
                        {errors.specialties}
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <label htmlFor="hearAboutUs" className="form-label">
                      How did you hear about Impulse?
                    </label>
                    <Field
                      as="select"
                      className={`form-select ${
                        touched.hearAboutUs && errors.hearAboutUs
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="hearAboutUs"
                      name="hearAboutUs"
                    >
                      <option value="">Select...</option>
                      <option value="search">Google Search</option>
                      <option value="social">Social Media</option>
                      <option value="colleague">Colleague Referral</option>
                      <option value="conference">Conference/Event</option>
                      <option value="other">Other</option>
                    </Field>
                    {touched.hearAboutUs && errors.hearAboutUs && (
                      <div className="invalid-feedback">
                        {errors.hearAboutUs}
                      </div>
                    )}
                  </div>
                </div>

                {error && (
                  <div className="alert alert-danger mt-3">{error}</div>
                )}

                <div className="d-flex justify-content-end gap-2 mt-4">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Schedule a Call'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div>
          <h5 className="modal-title mb-3">Book Your Consultation</h5>
          <p className="text-muted small">
            Thank you for sharing information about your practice. Select a time
            below to schedule a free 30-minute call. We’ll dig into your current
            challenges and explore how Impulse can help your business thrive.
          </p>

          {/* Optional checkbox or disclaimer (if desired) */}
          {/*
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="optIn"
              // This is just a visual example;
              // to handle it properly, you'd store this in Formik or local state
            />
            <label className="form-check-label" htmlFor="optIn">
              I agree to receive updates and resources from Impulse.
            </label>
          </div>
          */}

          <InlineWidget
            prefill={{
              name: submittedValues.name || '',
              email: submittedValues.email || '',
            }}
            url="https://calendly.com/michael-impulse/30min"
          />

          <div className="d-flex justify-content-end gap-2 mt-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleModalClose}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}
