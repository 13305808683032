"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tacticColors = void 0;
exports.tacticColors = [
    '#20303C',
    '#43515C',
    '#66737C',
    '#858F96',
    '#4196E0',
    '#459FED',
    '#57a8ef',
    '#32BABC',
    '#3CC7C5',
    '#64D4D2',
    '#32B76C',
    '#65C888',
    '#84D3A0',
    '#FAA030',
    '#FAAD4D',
    '#FBBD71',
    '#E66A4E',
    '#F27052',
    '#F37E63',
    '#EE2C38',
    '#F2564D',
    '#F57871',
    '#A0138E',
    '#B13DAC',
    '#C164BD',
];
