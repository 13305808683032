import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDebouncedCallback } from 'use-debounce';
import ContactFormModal from '../../components/CalendlyModal';
import Footer from '../../components/Footer';
import Header, { HeaderItem } from '../../components/Header';
import ImpulseSection from '../../components/SharedHomepageSection/ImpulseSection';
import FAQs from './sections/FAQs';
import GameplanSection from './sections/Gameplan';
import PrivacySection from './sections/Privacy';
import Slider from './sections/Slider';

const description =
  "We're interviewing parents about perspectives on childrens behavioral regulation";

export default function TherapistsPage() {
  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const refs = {
    interview: useRef<HTMLDivElement | null>(null),
    blocking: useRef<HTMLDivElement | null>(null),
    impulse: useRef<HTMLDivElement | null>(null),
  };
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const handleScroll = () => {
    setHeaderSticky(window.scrollY > 100);
  };
  const debouncedHandleScroll = useDebouncedCallback(handleScroll, 500, {
    trailing: true,
  });
  const openCalendlyModal = () => setIsCalendlyModalOpen(true);

  useEffect(() => {
    (window as any).initCanvas();
    (window as any).SEMICOLON.DocumentOnReady.init();
  }, []);

  const headerItems: HeaderItem[] = [
    {
      type: 'button',
      text: 'Chat with us',
      onClick: openCalendlyModal,
    },
    {
      type: 'link',
      text: 'Contact',
      href: 'mailto:hello@impulse.training',
    },
  ];

  useEffect(() => {
    document.addEventListener('scroll', debouncedHandleScroll);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Impulse | Help your clients break the cycle</title>
        <meta name="description" content={description} />
        <meta
          property="og:title"
          content="Impulse | Help your clients break the cycle"
        />
        <meta
          property="og:description"
          content="We're interviewing parents about perspectives on childrens behavioral regulation"
        />
      </Helmet>
      <Header isSticky={isHeaderSticky} items={headerItems} />
      <Slider openCalendlyModal={openCalendlyModal} />
      <ImpulseSection sectionRef={refs.impulse} />
      <GameplanSection openCalendlyModal={openCalendlyModal} />
      <PrivacySection openCalendlyModal={openCalendlyModal} />
      <FAQs />
      <Footer />
      <ContactFormModal
        isOpen={isCalendlyModalOpen}
        onClose={() => setIsCalendlyModalOpen(false)}
      />
    </div>
  );
}
