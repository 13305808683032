export default function FAQs() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8 text-center mb-6">
          <h3 className="h1 fw-bold mb-3">Frequently Asked Questions</h3>
        </div>

        <div className="clear"></div>
        <div className="col-lg-9">
          <div id="faqs" className="faqs">
            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">What is Impulse?</div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Impulse is a digital platform designed to help clients manage
                impulsive urges and reinforce therapeutic strategies. By
                integrating your interventions into a simple lock screen widget,
                it provides instant, real-time support between sessions.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  How does Impulse support my clients' treatment goals?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Impulse gives clients a personalized "gameplan" right on their
                phone's lock screen. When they feel an impulsive urge—like
                craving a substance or experiencing overwhelming anxiety—they
                can tap the widget to access coping techniques, grounding
                exercises, or reminders of their values. This helps bridge the
                gap between sessions by reinforcing skills you've introduced in
                therapy.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  Is Impulse customizable for each client's needs?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Absolutely. You can tailor interventions, create personalized
                prompts, and adjust coping strategies based on each client's
                unique challenges and therapeutic goals. This ensures the tool
                aligns with your clinical approach.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  How does Impulse protect client privacy and data?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                We designed Impulse with privacy in mind:
                <ul>
                  <li>
                    We do not collect personal identifiers like names or emails.
                  </li>
                  <li>
                    Reflections and logs are stored securely with
                    industry-standard encryption.
                  </li>
                  <li>
                    We are actively working toward HIPAA compliance, so you can
                    feel confident about data security and confidentiality.
                  </li>
                </ul>
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  Is Impulse HIPAA-compliant?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Not at this time. We're diligently building out our
                infrastructure and security safeguards to meet HIPAA's
                standards. Until we achieve full compliance, we recommend using
                Impulse in a manner that does not involve transmitting or
                storing personally identifiable health information.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3 faq-authors faq-miscellaneous">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  Can my clients use Impulse on any device?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Impulse is currently available for smartphones (iOS and/or
                Android), making it easy for clients to integrate it into their
                everyday routines. We continuously update our platform to ensure
                a user-friendly, accessible experience.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  What does it cost to use Impulse?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                We're developing flexible pricing models, including free or
                low-cost pilot opportunities for therapists who want to test the
                platform with their clients. Contact us to learn about current
                subscription rates and potential group discounts for your
                practice.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  How do I get started integrating Impulse into my practice?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Simply sign up on our website to create a therapist account.
                Once inside, you can set up custom interventions, invite clients
                to download the app, and begin tailoring prompts that support
                their treatment goals. Our team offers onboarding support to
                help you make the most of the platform.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  What if my client experiences a crisis using Impulse?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Impulse is not intended to replace emergency services. We advise
                you to maintain standard crisis protocols, including providing
                clients with emergency contacts and crisis hotlines. Impulse
                offers real-time coping strategies, but it's crucial for clients
                to seek professional help or dial 911 if they're in an immediate
                crisis.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  Does Impulse share data with third parties or train AI models
                  with my interventions?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                No. We do not use therapists' custom interventions for AI
                training, nor do we share data with third parties. Our goal is
                to maintain trust and confidentiality, focusing on empowering
                your clients rather than leveraging their data.
              </div>
            </div>

            <div className="toggle faq pb-3 mb-3">
              <div className="toggle-header">
                <div className="toggle-icon">
                  <i className="toggle-closed bi-question-circle"></i>
                  <i className="toggle-open bi-question-circle"></i>
                </div>
                <div className="toggle-title ps-1">
                  Can I provide feedback or request new features?
                </div>
                <div className="toggle-icon">
                  <i className="toggle-closed fa-solid fa-chevron-down"></i>
                  <i className="toggle-open fa-solid fa-chevron-up"></i>
                </div>
              </div>
              <div className="toggle-content ps-4">
                Absolutely! We value collaboration with mental health
                professionals. Reach out to our support team or join our
                therapist network to share your insights and help shape future
                platform updates.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
