interface Props {
  openCalendlyModal: () => void;
}
export default function GameplanSection({ openCalendlyModal }: Props) {
  return (
    <div
      style={{ backgroundColor: '#f0f0f0' }}
      className="row justify-content-between align-items-center py-md-6"
    >
      <div className="col-lg-5">
        <div className="col-padding">
          <h2 className="h1 fw-semibold display mb-0">
            Tailor gameplans for your clients
          </h2>
          <p className="lead mb-4">
            Impulse gives your clients instant access to grounding techniques,
            coping strategies and other exercises when they press the impulse
            button - just when they need them most. Add videos, questions, voice
            notes and more to help them be prepared when they need support the
            most.
          </p>
          <button
            className="btn px-5 py-3 btn-dark rounded-0"
            onClick={openCalendlyModal}
          >
            Chat with us
          </button>
        </div>
      </div>

      <div className="col-lg-7">
        <div
          id="oc-images"
          className="owl-carousel image-carousel carousel-widget"
          data-nav="false"
          data-margin="20"
          data-items-xs="2"
          data-items-sm="3"
          data-items-md="4"
          data-items-lg="3"
          data-items-xl="3"
        >
          <div className="oc-item">
            <img src="images/gameplan/tactic1.png" alt="Image 1" />
          </div>
          <div className="oc-item">
            <img src="images/gameplan/tactic2.png" alt="Image 2" />
          </div>
          <div className="oc-item">
            <img src="images/gameplan/tactics.png" alt="Image 5" />
          </div>
        </div>
      </div>
    </div>
  );
}
