"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isContactsTactic = exports.isTaskTactic = exports.isBreatheTactic = exports.isVideoTactic = exports.isAudioTactic = exports.isStepsTactic = void 0;
const isStepsTactic = ({ type }) => type === 'steps';
exports.isStepsTactic = isStepsTactic;
const isAudioTactic = ({ type }) => type === 'audio';
exports.isAudioTactic = isAudioTactic;
const isVideoTactic = ({ type }) => type === 'video';
exports.isVideoTactic = isVideoTactic;
const isBreatheTactic = ({ type }) => type === 'breathingExercise';
exports.isBreatheTactic = isBreatheTactic;
const isTaskTactic = ({ type }) => type === 'task';
exports.isTaskTactic = isTaskTactic;
const isContactsTactic = ({ type }) => type === 'notifyASupportPerson';
exports.isContactsTactic = isContactsTactic;
