interface Props {
  openCalendlyModal: () => void;
}

export default function PrivacySection({ openCalendlyModal }: Props) {
  return (
    <div className="block-hero-21" style={{ padding: '130px 0' }}>
      <div className="container">
        <div className="row flex-row-reverse justify-content-between align-items-center g-6">
          <div className="col-lg-6 mt-5 mt-lg-0">
            <img
              src="images/blocks/preview/hero-21/1.svg"
              alt="Image"
              className="z-1 position-relative"
              style={{ marginBottom: '-60px;' }}
            />
          </div>
          <div className="col-lg-6">
            <h2 className="h1 fw-semibold display ">
              Privacy and Security by Design
            </h2>
            <p className="mw-xs fw-normal mb-5 text-larger">
              At Impulse, we prioritize the security and confidentiality of your
              practice. Our platform is built with HIPAA compliance and data
              protection at its core.
            </p>
          </div>
        </div>
        <div className="row justify-content-between col-mb-50">
          <div className="col-6 col-lg-3">
            <i className="fa-solid fa-shield-halved fa-2x text-primary"></i>

            <h4 className="my-3">Zero-Knowledge Architecture</h4>
            <p className="text-muted">
              Client identities are stored securely in your device's keychain,
              never shared with our servers. We maintain zero personally
              identifiable information.
            </p>
          </div>

          <div className="col-6 col-lg-3">
            <i className="fa-solid fa-file-contract fa-2x text-primary"></i>
            <h4 className="my-3">HIPAA Compliance</h4>
            <p className="text-muted">
              Our Business Associate Agreement outlines comprehensive data
              protection measures, ensuring your practice meets all HIPAA
              requirements.
            </p>
          </div>

          <div className="col-6 col-lg-3">
            <i className=" fa-solid fa-lock fa-2x text-primary"></i>
            <h4 className="my-3">End-to-End Encryption</h4>
            <p className="text-muted">
              All data is encrypted in transit and at rest using
              industry-standard protocols, ensuring the highest level of
              security for your practice.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 text-center">
            <div className="fancy-title title-border title-center mb-4">
              <h3 className="fw-semibold">Ready to learn more?</h3>
            </div>
            <button
              className="button button-large button-rounded bg-primary"
              onClick={openCalendlyModal}
            >
              Chat with us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
